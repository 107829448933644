<template>
  <div class="disinfectRecord">
    <BaseDialog
      title="透析机消毒记录信息"
      :isshow="visible"
      @handleShow="
        (val) => {
          visible = val
        }
      "
      width="570px"
      height="auto"
    >
      <div class="cont-main">
        <el-scrollbar>
          <!-- 输入框部分 -->
          <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="Rule.DISINFECTRECORD"
            label-width="140px"
            label-position="right"
            class="demo-ruleForm col-333"
          >
            <el-form-item label="院区：" prop="hospital_area_id">
              <el-select
                v-model="ruleForm.hospital_area_id"
                @change="hospitalChe"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in hospital_area"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="床位号：" prop="bed_no">
              <el-select
                @change="bedChang"
                v-model="ruleForm.bed_no"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in bedList"
                  :key="item.id"
                  :label="item.bed_no"
                  :value="item.bed_no"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="透析机编号：" prop="machine_no">
              <el-input
                v-model="ruleForm.machine_no"
                disabled
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="日期：" prop="record_time">
              <el-date-picker
                v-model="ruleForm.record_time"
                type="date"
                value-format="YYYY-MM-DD"
                placeholder="请选择"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="消毒时间：">
              <el-time-picker
                v-model="ruleForm.start_time"
                value-format="HH:mm"
                format="HH:mm"
                placeholder="开始时间"
              >
              </el-time-picker>
            </el-form-item>
            <el-form-item label="消毒程序：">
              <el-select v-model="ruleForm.dict_degassing" placeholder="请选择">
                <el-option
                  v-for="item in dictList[1345439309750000]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="床单更换：">
              <el-select
                v-model="ruleForm.sheet_change_flag"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in dictList[221000000]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="床单元终末消毒：">
              <el-select
                v-model="ruleForm.sheet_disinfect_flag"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in dictList[620000000]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="表面消毒类型：">
              <el-checkbox-group v-model="ruleForm.dict_surface_sterilize">
                <el-checkbox
                  v-for="item in dictList[220000000]"
                  :key="item.id"
                  :label="item.code"
                  >{{ item.name }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
            <!-- <el-form-item label="结束时间：">
              <el-time-picker
                v-model="ruleForm.end_time"
                value-format="HH:mm"
                format="HH:mm"
                placeholder="结束时间"
              >
              </el-time-picker>
            </el-form-item> -->
            <el-form-item label="操作者：">
              <el-select
                v-model="ruleForm.operator_id_one"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in userInfoCZZ"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="操作者2：">
              <el-select
                v-model="ruleForm.operator_id_two"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in userList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item> -->
          </el-form>
        </el-scrollbar>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="visible = false"
          >
            取消
          </el-button>
          <el-button
            @click="estimateSave"
            :loading="saveLoad"
            class="color-determine"
            type="primary"
          >
            确定
          </el-button>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, nextTick } from 'vue'
import Rule from '@/utils/rule'
import service from '@/utils/request'
import { ElMessage } from 'element-plus'
import { gitDictList } from '@/utils/tool'
import BaseDialog from '@/components/Dialog/index.vue'
import dayjs from 'dayjs'
export default {
  components: {
    BaseDialog,
  },
  emits: ['refreshDataList'],
  setup(props, ctx) {
    const userInfo = JSON.parse(localStorage.getItem('ms_userData'))
    const state = reactive({
      dictList: {},
      ruleForm: {
        dict_surface_sterilize: [],
      },
      searchData: {},
      Rule: Rule,
      visible: false,
      ruleFormRef: null,
      saveLoad: false,
      hospital_area: [],
      bedList: [],
      userList: [],
      userInfoCZZ:[],
      userNameList: [
        {
          name: localStorage.getItem('ms_username'),
          code: userInfo.uid,
          id: userInfo.uid,
        },
      ],
    })
    onMounted(() => {
      getDict()
      getHospitalList()
      getUserList()
    })
    const initData = (item) => {
      state.visible = true
      state.searchData = item
      getDataList()
    }
    const getDataList = async () => {
      state.userInfoCZZ = []
      state.ruleForm.patient_id = state.patientId
      let res = await service.post('api/estimate/degassingList', {
        record_id: state.searchData.record_id,
      })
      if (res.code === 0) {
        state.ruleForm = res.data
        if (!state.ruleForm.dict_surface_sterilize) {
          state.ruleForm.dict_surface_sterilize = []
        }
        if (!state.ruleForm.operator_id_1) {
          state.ruleForm.operator_id_1 = userInfo.uid
          state.userInfoCZZ = userInfo
        }else{
          let obj = {
              name:state.ruleForm.operator_id_1_name,
              id:state.ruleForm.operator_id_1
            }
            state.userInfoCZZ.push(obj,...userInfo)
        }
        console.log('state.userInfoCZZ==',state.userInfoCZZ)
        if (!state.ruleForm.sheet_disinfect_flag) {
          state.ruleForm.sheet_disinfect_flag = '621000000'
        }
        if (!state.ruleForm.start_time) {
          state.ruleForm.start_time = dayjs(new Date()).format('HH:mm')
        }
        if (!state.ruleForm.end_time) {
          state.ruleForm.end_time = dayjs(new Date())
            .add(30, 'minute')
            .format('HH:mm')
        }
        getBedList(state.ruleForm.hospital_area_id)
      }
    }
    const getDict = async () => {
      state.dictList = await gitDictList([
        '220000000',
        '1345439309750000',
        '221000000',
        '620000000',
      ])
    }
    const getHospitalList = async () => {
      let res = await service.post('/api/hospital/list', {
        parent_id: 0,
      })
      if (res.code === 0) {
        state.hospital_area = res.data
      }
    }
    const hospitalChe = (val) => {
      getBedList(val)
    }
    const getBedList = async (val) => {
      let res = await service.post('/api/bed/list', {
        size: 100,
        hospital_area_id: val,
      })
      if (res.code === 0) {
        state.bedList = res.data.records
        getMachineNo()
      }
    }
    const getUserList = async () => {
      // let res = await service.post('/api/user/list')
      // if (res.code === 0) {
      //   state.userList = res.data.records
      // }
      state.userList = [JSON.parse(localStorage.getItem('ms_userData'))]
        console.log(state.userList)
    }
    const bedChang = () => {
      nextTick(() => {
        getMachineNo()
      })
    }
    const getMachineNo = () => {
      if (!state.ruleForm.bed_no) return
      state.bedList.map((item) => {
        if (item.bed_no === state.ruleForm.bed_no) {
          state.ruleForm.machine_no = item.machine_no
        }
      })
    }
    const estimateSave = () => {
      state.ruleFormRef.validate(async (valid) => {
        if (valid) {
          state.saveLoad = true
          let res = await service.post(
            '/api/estimate/degassingSave',
            state.ruleForm
          )
          state.saveLoad = false
          if (res.code === 0) {
            ElMessage.success(res.msg)
            state.visible = false
            ctx.emit('refreshDataList')
          }
        }
      })
    }
    return {
      ...toRefs(state),
      estimateSave,
      initData,
      hospitalChe,
      bedChang,
    }
  },
}
</script>

<style scoped lang="scss">
.cont-main {
  height: 500px;
  margin-top: 20px;
  .el-form {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 20px;
    padding-right: 40px;
  }
  .templateBut {
    width: 88px;
    margin-left: 12px;
    display: inline-block;
    position: relative;
    top: 7px;
    .tip {
      margin-left: 0;
      margin-top: 6px;
    }
  }
}
</style>
